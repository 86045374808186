import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import Logo from "../../Assets/Company/logo.png";
import LaptopChromebookTwoToneIcon from "@mui/icons-material/LaptopChromebookTwoTone";
import VpnKeyTwoToneIcon from "@mui/icons-material/VpnKeyTwoTone";
import GppGoodTwoToneIcon from "@mui/icons-material/GppGoodTwoTone";

const MobileView = () => {
  return (
    <Box
      sx={{
        p: ".5rem",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            height: "3rem",
            width: "3rem",
            borderRadius: "50%",
          }}
        >
          <img
            src={Logo}
            style={{ height: "100%", width: "100%" }}
            alt="Icon"
            onError={(e) => {
              e.onerror = null;
              e.target.src = require("../../Assets/Images/no-preview.jpeg");
            }}
          />
        </Box>
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: "1.5rem",
            ml: "1rem",
          }}
        >
          VoyagersTales
        </Typography>
      </Box>
      <Box
        sx={{
          backgroundColor: "White",
          borderRadius: "5px",
          padding: "1rem",
          mt: "1rem",
        }}
      >
        <Typography
          sx={{
            textAlign: "center",
            fontWeight: "bold",
            fontSize: "1.3rem",
          }}
        >
          Hi {localStorage.getItem("hostFirstName")},
        </Typography>
        <Typography
          sx={{
            textAlign: "center",
            fontWeight: "bold",
            fontSize: "1.3rem",
          }}
        >
          Your account is activated!
        </Typography>
        <Typography
          sx={{
            textAlign: "center",
            fontSize: ".9rem",
            mt: ".5rem",
            color: "gray",
          }}
        >
          Please log in to <b>https://partner.voyagerstales.com</b> on your
          PC to view your dashboard and it's features.
        </Typography>
      </Box>

      <Box
        sx={{
          backgroundColor: "White",
          borderRadius: "5px",
          padding: "1rem",
          pt: "2rem",
          mt: "1.5rem",
        }}
      >
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: ".8rem",
          }}
        >
          Here's how to check your dashboard on Voyagers:
        </Typography>
        <Box
          sx={{
            display: "flex",
            width: "90%",
            mt: "2rem",
          }}
        >
          <Box>
            <LaptopChromebookTwoToneIcon style={{ fontSize: "46px" }} />
          </Box>
          <Box
            sx={{
              ml: "1rem",
            }}
          >
            <Typography
              sx={{
                color: "gray",
                fontSize: ".7rem",
                fontWeight: "bold",
              }}
            >
              Step 1
            </Typography>
            <Typography
              sx={{
                fontSize: ".75rem",
              }}
            >
              Use Your PC to access your Host dashboard.
            </Typography>
            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: ".7rem",
                mt: ".5rem",
              }}
            >
              https://partner.voyagerstales.com
            </Typography>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            width: "90%",
            mt: "2rem",
          }}
        >
          <Box>
            <VpnKeyTwoToneIcon style={{ fontSize: "46px" }} />
          </Box>
          <Box
            sx={{
              ml: "1rem",
            }}
          >
            <Typography
              sx={{
                color: "gray",
                fontSize: ".7rem",
                fontWeight: "bold",
              }}
            >
              Step 2
            </Typography>
            <Typography
              sx={{
                fontSize: ".75rem",
              }}
            >
              Login with your registered email id and password.
            </Typography>
            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: ".7rem",
                mt: ".5rem",
              }}
            >
              {localStorage.getItem("hostEmail")}
            </Typography>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            width: "90%",
            mt: "2rem",
          }}
        >
          <Box>
            <GppGoodTwoToneIcon style={{ fontSize: "46px" }} />
          </Box>
          <Box
            sx={{
              ml: "1rem",
            }}
          >
            <Typography
              sx={{
                color: "gray",
                fontSize: ".7rem",
                fontWeight: "bold",
              }}
            >
              Step 3
            </Typography>
            <Typography
              sx={{
                fontSize: ".75rem",
              }}
            >
              You can check your Bookings and other features related to your
              Dashboard.
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default MobileView;
