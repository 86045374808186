import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import { DrawerMenu } from "../../component/constant/DrawerMenu/DrawerMenu";
import DashboardPage from "../../component/DashboardPage";
import Bookings from "../../component/Bookings";
import ApproveBookings from "../../component/ApproveBookings";
import Profile from "../Profile";
import { useNavigate } from "react-router-dom";
import Logo from '../../Assets/Company/logo.png'
import axios from "axios";
import UserAvatar from '../../Assets/Images/avatar.png'

const drawerWidth = 240;

const Dashboard = () => {
  const navigate = useNavigate();
  const [userName, setUserName] = useState("");
  const [userLogo, setUserLogo] = useState(null)
  const [propertyData, setPropertyData] = useState([]);

  const [selectedMenu, setSelectedMenu] = useState("Dashboard");

  useEffect(() => {
    if (!localStorage.getItem("hostRole")) {
      navigate("/login");
    } else {
      setUserName(
        `${localStorage.getItem("hostFirstName")}${" "}${localStorage.getItem(
          "hostLastName"
        )}`
      );
      if(localStorage.getItem("hostAvatar") === null){
        setUserLogo(localStorage.getItem("hostAvatar"))
      }
      else{
        setUserLogo(UserAvatar)
      }
      handleGetProperty();
    }
  }, []);
  

  const handleGetProperty = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API}api/get-user-property/`,
        {
          headers: {
            Authorization: `Token ${localStorage.getItem("hostToken")}`,
          },
        }
      );
      setPropertyData(res?.data?.get_property);
    } catch (err) {
      console.log(err);
    }
  };

  const handleLogout = () => {
    localStorage.clear();
    navigate("/login");
  };

  const renderContent = () => {
    switch (selectedMenu) {
      case "Dashboard":
        return propertyData && propertyData.length > 0 ? <DashboardPage PropertyData={propertyData} /> : <Typography>No Property Found</Typography>;
      case "Bookings":
        return <Bookings propertyNames={propertyData} />;
      case "Approve Bookings":
        return <ApproveBookings propertyNames={propertyData} />;
      case "Logout":
        handleLogout();
        return null;
      case "Profile":
        return <Profile />;
      default:
        return <Typography paragraph>Select a menu item</Typography>;
    }
  };

  return (
    <Box sx={{ display: "flex", backgroundColor: "#f6f6f6" }}>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <Toolbar
          sx={{
            padding: "0 !important",
            px: ".5rem !important",
          }}
        >
          <Box
            sx={{
              height: "100%",
              width: "100%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                height: "3rem",
                width: "3rem",
                borderRadius: "50%",
              }}
            >
              <img src={Logo} style={{height:"100%", width:"100%"}} alt="Icon" onError={(e) => {
                e.onerror = null;
                e.target.src = require("../../Assets/Images/no-preview.jpeg");
              }} />
            </Box>
            <Typography
              sx={{
                fontFamily: "Quicksand",
                fontWeight: "bold",
                fontSize: "1.25rem",
                ml: ".5rem",
              }}
            >
              Voyagers Tales
            </Typography>
          </Box>
        </Toolbar>
        <List>
          {DrawerMenu.map((item, index) => (
            <ListItem key={item.menu} disablePadding>
              <ListItemButton
                selected={selectedMenu === item.menu}
                onClick={() => setSelectedMenu(item.menu)}
                sx={{
                  "&.Mui-selected": {
                    backgroundColor: "#edeeff",
                    borderRight: ".5rem solid #232675",
                    borderRadius: "5px",
                    "&:hover": {
                      backgroundColor: "#edeeff",
                    },
                  },
                }}
              >
                <ListItemIcon
                  sx={{
                    color:
                      item.menu === "Logout"
                        ? "red"
                        : selectedMenu === item.menu
                        ? "#232675"
                        : "inherit",
                  }}
                >
                  {item.icon}
                </ListItemIcon>
                <ListItemText
                  primary={item.menu}
                  sx={{
                    color: item.menu === "Logout" ? "red" : "inherit",
                  }}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <AppBar
          position="relative"
          sx={{
            backgroundColor: "transparent",
            boxShadow: "none",
          }}
        >
          <Toolbar>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Box>
                <Typography
                  sx={{
                    color: selectedMenu === "Dashboard" ? "#363986" : "black",
                    fontSize: "2rem",
                    fontWeight: "bold",
                    fontFamily: "Quicksand",
                  }}
                >
                  {selectedMenu === "Dashboard"
                    ? "Welcome Back!"
                    : selectedMenu === "Profile"
                    ? "User Profile Details"
                    : "Booking Details"}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    height: "3rem",
                    width: "3rem",
                  }}
                >
                  <Box
                    sx={{
                      height: "100%",
                      width: "100%",
                      borderRadius: "50%",
                      cursor: "pointer",
                    }}
                    onClick={() => setSelectedMenu("Profile")}
                  ><img src={userLogo} style={{height:"100%", width:"100%"}} alt="Icon" onError={(e) => {
                    e.onerror = null;
                    e.target.src = require("../../Assets/Images/no-preview.jpeg");
                  }} /></Box>
                </Box>
                <Box
                  sx={{
                    ml: "1rem",
                    cursor: "pointer",
                  }}
                  onClick={() => setSelectedMenu("Profile")}
                >
                  <Typography
                    sx={{
                      color: "black",
                    }}
                  >
                    {userName}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Toolbar>
        </AppBar>
        {renderContent()}
      </Box>
    </Box>
  );
};

export default Dashboard;
