import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import SearchBar from "../SearchBar";
import ApproveFilters from "../ApproveFilters";
import Tables from "../UI/table";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import axios from "axios";
import Buttons from "../UI/Buttons";

const tableHead = [
  "Booked on",
  "Guest Name",
  "Booking Id",
  "Number of guests",
  "Checkin",
  "Checkout",
  "",
];

const ApproveBookings = ({ propertyNames }) => {
  const [value, setValue] = useState(propertyNames[0]?.id || "");
  const [bookingData, setBookingData] = useState([]);
  const [isSearch, setIsSearch] = useState([]);

  useEffect(() => {
    // Update state if propertyNames changes and the current value is not in the new list
    if (!propertyNames.some((item) => item.id === value)) {
      setValue(propertyNames[0]?.id || "");
    }
    if (isSearch.length === 0) {
      handleGetProperty();
    }
  }, [propertyNames, value, isSearch]);

  const handleSearchProperty = async (typedText) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API}api/get-booking-by-id/?booking_id=${typedText}&booking_status=1`,
        {
          headers: {
            Authorization: `Token ${localStorage.getItem("hostToken")}`,
          },
        }
      );
      setIsSearch(res?.data?.data);
    } catch (err) {
      console.error("Error searching property:", err);
    }
  };

  const handleGetProperty = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API}api/get-booking-by-property/?property_id=${value}`,
        {
          headers: {
            Authorization: `Token ${localStorage.getItem("hostToken")}`,
          },
        }
      );
      setBookingData(res?.data);
    } catch (err) {
      console.error("Error fetching property data:", err);
    }
  };

  const handleChange = (event) => {
    const selectedValue = event.target.value;
    setValue(selectedValue);
    handleGetProperty();
  };

  const handleClearSearch = () => {
    handleGetProperty();
    setIsSearch([]);
  };

  const handleStatus = async (id, statusCode) => {
    const formData = new URLSearchParams();
    formData.append('booking_status', statusCode);
  
    try {
      const res = await axios.patch(
        `${process.env.REACT_APP_API}api/approved-cancel/reserve-destination/${id}/`,
        formData.toString(),
        {
          headers: {
            Authorization: `Token ${localStorage.getItem("hostToken")}`,
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        }
      );
      console.log("--", res);
      handleGetProperty();
    } catch (err) {
      console.error("Error updating booking status:", err);
    }
  };
  return (
    <>
      <Box
        sx={{
          width: "20%",
        }}
      >
        {propertyNames && propertyNames.length > 0 && (
          <Box
          sx={{
            p: ".3rem",
            px: ".5rem",
            border: "1px solid black",
            borderRadius: "8px",
            backgroundColor: "White",
            boxShadow: "4px 4px 4px 0px rgba(127, 130, 222, 0.3)",
            mt: "2rem",
          }}
        >
          <Typography
            sx={{
              fontSize: "0.9rem",
            }}
          >
            Select Property
          </Typography>
          <Box>
            <FormControl fullWidth>
              <Select
                value={value}
                onChange={handleChange}
                displayEmpty
                inputProps={{ "aria-label": "Select an Option" }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      mt: ".8rem",
                    },
                  },
                }}
                sx={{
                  height: 30,
                  fontSize: "0.9rem",
                  "& .MuiSelect-select": {
                    padding: "8px 0",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                }}
              >
                {propertyNames.map((item) => (
                  <MenuItem
                    key={item?.property_name}
                    value={item?.id}
                    sx={{
                      fontSize: "0.8rem",
                    }}
                  >
                    {item?.property_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Box>
        )}
      </Box>
      <Box
        sx={{
          width: "100%",
        }}
      >
        <SearchBar toSearch={handleSearchProperty} />
      </Box>

      {isSearch.length === 0 ? (
        <Box
        sx={{
          width: "90%",
          mt: "1.5rem",
        }}
      >
        <ApproveFilters />
      </Box>
      ) : (
        <Box
          sx={{
            width: "81%",
            mt: "1.5rem",
            display:"flex",
            justifyContent:"end",
          }}
        >
          <Buttons text="Clear" btnClick={handleClearSearch} bgColor="#232675" textColor="White" btnWidth="12%" />
        </Box>
      )}

      <Box
        sx={{
          width: "90%",
          mt: "3rem",
        }}
      >
        {isSearch.length === 0 ? (
          <Tables headData={tableHead} bodyData={bookingData} approve={true} statusBtn={handleStatus} />
        ) : (
          <Tables headData={tableHead} bodyData={isSearch} approve={true} />
        )}
      </Box>
    </>
  );
};

export default ApproveBookings;