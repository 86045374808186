import DashboardIcon from '@mui/icons-material/Dashboard';
import WorkIcon from '@mui/icons-material/Work';
import GradingIcon from '@mui/icons-material/Grading';
import LogoutIcon from '@mui/icons-material/Logout';

export const DrawerMenu = [
    {
        menu:"Dashboard",
        icon:<DashboardIcon />,
    },
    {
        menu:"Bookings",
        icon:<WorkIcon />,
    },
    {
        menu:"Approve Bookings",
        icon:<GradingIcon />,
    },
    {
        menu:"Logout",
        icon:<LogoutIcon />,
    },
]