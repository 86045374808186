import React from 'react'
import Button from '@mui/material/Button'

const Buttons = ({text, bgColor, textColor, textWeight="normal", buttonBorder="none", btnIcon="", btnClick, btnWidth="inherit", btnHorizontalPadding, btnVerticalPadding, btnType}) => {
  return (
    <>
    <Button
    startIcon={btnIcon}
    onClick={btnClick}
    type={btnType}
    sx={{
        backgroundColor:bgColor,
        color:textColor,
        textTransform:"none",
        fontFamily:"Quicksand",
        fontWeight:textWeight,
        border:buttonBorder,
        borderRadius:"5px",
        width:btnWidth,
        px:btnHorizontalPadding,
        py:btnVerticalPadding,
        '&:hover':{
            backgroundColor:bgColor,
        color:textColor,
        }
    }}
    >{text}</Button>
    </>
  )
}

export default Buttons