import "./App.css";
import Box from "@mui/material/Box";
import ProtectedRoutes from "./component/ProtectedRoutes";
import { BrowserRouter as Router } from "react-router-dom";

function App() {
  return (
    <Router>
      <Box
        sx={{
          fontFamily: "Quicksand",
          backgroundColor: "#f6f6f6",
        }}
      >
        <ProtectedRoutes />
      </Box>
    </Router>
  );
}

export default App;
