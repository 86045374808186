export const CheckinDate = [
    "All",
    "Today",
    "Yesterday",
    "Last 7 Days",
    "Last 30 Days",
    "Current Month",
    "Last Month",
    "Current Date Range",
    "Start date - End Date",
]

export const CheckoutDate = [
    "All",
    "Today",
    "Yesterday",
    "Last 7 Days",
    "Last 30 Days",
    "Current Month",
    "Last Month",
    "Current Date Range",
    "Start date - End Date",
]

export const BookingDate = [
    "All",
    "Today",
    "Yesterday",
    "Last 7 Days",
    "Last 30 Days",
    "Current Month",
    "Last Month",
    "Current Date Range",
    "Start date - End Date",
]

export const StatusDate = [
    "Booked",
    "Cancelled",
]