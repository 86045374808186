import React, { useState, useRef } from 'react';
import Box from '@mui/material/Box';
import {
  CheckinDate,
  CheckoutDate,
  BookingDate,
  StatusDate,
} from '../constant/FIlterData';
import Filters from '../Filters';
import Buttons from '../UI/Buttons';
import CustomiseCalendar from '../UI/Calendar';
import axios from 'axios';

const BookingFilters = ({ allBtn, upcomingBtn, pastBtn, btnCode, updateRange, setUpdateRange, applyBtn, statusChange, value }) => {
  const [openCal, setOpenCal] = useState(false);
  const refOne = useRef(null);

  const calenderdirection = 'horizontal';
  const calenderHeight = '18rem';
  const calenderWidth = '15rem';

  

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
          }}
        >
          <Buttons text="All Booking" bgColor= {btnCode === 1 ? "#232675" : "#e87b26"} textColor="White" btnClick={allBtn} />
          <Box
            sx={{
              ml: '1rem',
            }}
          >
            <Buttons text="Upcoming Booking" bgColor={btnCode === 2 ? "#232675" : "#e87b26"} textColor="White" btnClick={upcomingBtn} />
          </Box>
          <Box
            sx={{
              ml: '1rem',
            }}
          >
            <Buttons text="Past Booking" bgColor={btnCode === 3 ? "#232675" : "#e87b26"} textColor="White" btnClick={pastBtn} />
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mt: '3rem',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '46%',
          }}
        >
          <CustomiseCalendar
            openCal={openCal}
            setOpenCal={setOpenCal}
            refOne={refOne}
            range={updateRange}
            setRange={setUpdateRange}
            calenderdirection={calenderdirection}
            calenderHeight={calenderHeight}
            calenderWidth={calenderWidth}
          />
          <Filters filterName="Status" data={StatusDate} filterChange={statusChange} statusValue={value} />
        </Box>
        <Buttons
          text="Apply Filters"
          bgColor="White"
          textColor="black"
          buttonBorder="1px solid black"
          textWeight="bold"
          btnClick={applyBtn}
        />
      </Box>
    </>
  );
};

export default BookingFilters;