import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

const UserDetails = ({field, value}) => {
  return (
    <Box
    sx={{
        display:"flex"
    }}
    >
        <Typography
        sx={{
            fontWeight:"bold",
        }}
        >{field}</Typography>
        <Typography
        sx={{
            ml:'.5rem',
        }}
        >{value}</Typography>
    </Box>
  )
}

export default UserDetails