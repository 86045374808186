import React from 'react'
import TextField from '@mui/material/TextField'

const Textfields = ({ labelText, fieldType='text', fieldChange, textValue, fieldName }) => {
  return (
    <TextField
    required
    label={labelText}
    type={fieldType}
    value={textValue}
    name={fieldName}
    sx={{
      width:"100%"
    }}
    onChange={fieldChange}
    />
  )
}

export default Textfields