import React,{ useState, useRef } from "react";
import Box from "@mui/material/Box";
import addDays from 'date-fns/addDays';

import {
  CheckinDate,
  CheckoutDate,
} from "../constant/FIlterData";
import Filters from "../Filters";
import Buttons from "../UI/Buttons";
import CustomiseCalendar from "../UI/Calendar";

const ApproveFilters = () => {
  const [openCal, setOpenCal] = useState(false);
  const refOne = useRef(null);
  const [range, setRange] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 2),
      key: 'selection',
    },
  ]);

  const calenderdirection = 'horizontal';
  const calenderHeight = '18rem';
  const calenderWidth = '15rem';

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mt: "3rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
          }}
        >
          <CustomiseCalendar
            openCal={openCal}
            setOpenCal={setOpenCal}
            refOne={refOne}
            range={range}
            setRange={setRange}
            calenderdirection={calenderdirection}
            calenderHeight={calenderHeight}
            calenderWidth={calenderWidth}
          />
        </Box>
        <Buttons text="Apply Filters" bgColor="White" textColor="black" buttonBorder="1px solid black" textWeight="bold" />

      </Box>
    </>
  );
};

export default ApproveFilters;
