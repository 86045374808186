import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const Tables = ({ headData, bodyData, approve = false, statusBtn }) => {
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: "numeric", month: "short", year: "numeric" };
    return new Intl.DateTimeFormat("en-GB", options).format(date);
  };

  return (
    <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
      {bodyData.length !== 0 ? (
        <Table>
          <TableHead>
            <TableRow
              sx={{
                backgroundColor: "#eeeeee",
              }}
            >
              {headData?.map((item, index) => (
                <TableCell
                  key={index}
                  sx={{
                    fontWeight: "bold",
                    border: "none",
                  }}
                >
                  {item}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {bodyData?.map((row, index) => (
              <TableRow key={index}>
                <TableCell sx={{ border: "none" }}>
                  {formatDate(row?.created_date)}
                </TableCell>
                <TableCell sx={{ border: "none" }}>
                  {row?.first_name + " " + row?.last_name}
                </TableCell>
                <TableCell sx={{ border: "none" }}>{row?.booking_id}</TableCell>
                <TableCell sx={{ border: "none" }}>
                  {row?.total_adults}
                </TableCell>
                <TableCell sx={{ border: "none" }}>
                  {formatDate(row?.check_in)}
                </TableCell>
                <TableCell sx={{ border: "none" }}>
                  {formatDate(row?.check_out)}
                </TableCell>
                {!approve && (
                  <TableCell sx={{ border: "none" }}>
                    <Box
                      sx={{
                        backgroundColor:
                          row?.booking_status === 2 ? "green" : "red",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: ".5rem",
                        borderRadius: "8px",
                        color: "White",
                        fontWeight: "bold",
                      }}
                    >
                      {row?.booking_status === 2 ? "Booked" : "Canceled"}
                    </Box>
                  </TableCell>
                )}
                {approve && (
                  <TableCell sx={{ border: "none" }}>
                    <Box
                      sx={{
                        display: "flex",
                      }}
                    >
                      <Button
                        sx={{
                          borderRadius: "8px 0 0 8px",
                          backgroundColor: "#232675",
                          color: "White",
                          textTransform: "none",
                          fontWeight: "bold",
                          border: "1px solid black",
                          borderRight: "none",
                          "&:hover": {
                            backgroundColor: "#232675",
                            color: "White",
                          },
                        }}
                        onClick={() => statusBtn(row?.id, 3)}
                      >
                        Cancel
                      </Button>
                      <Button
                        sx={{
                          borderRadius: "0 8px 8px 0",
                          backgroundColor: "White",
                          color: "#232675",
                          textTransform: "none",
                          fontWeight: "bold",
                          border: "1px solid black",
                          borderLeft: "none",
                          "&:hover": {
                            backgroundColor: "White",
                            color: "#232675",
                          },
                        }}
                        onClick={() => statusBtn(row?.id, 2)}
                      >
                        Approve
                      </Button>
                    </Box>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : (
        <Typography
        sx={{
          backgroundColor:"#f6f6f6",
          textAlign:"center",
          fontSize:"1.5rem"
        }}
        >
          No Booking Found! <br /> We are doing Our best to provide you Bookings.
        </Typography>
      )}
    </TableContainer>
  );
};

export default Tables;
