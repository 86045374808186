import Login from "./pages/Login"
import Dashboard from "./pages/Dashboard"
import MobileView from "./pages/MobileView"

export const routes = [
    {
        path:'/login',
        component:<Login />
    },
    {
        path:'/',
        component:<Dashboard />
    },
    {
        path:'/mobile',
        component:<MobileView />
    },
]