import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import DateRange from 'react-date-range/dist/components/DateRange';
import moment from 'moment/moment';
import format from 'date-fns/format';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

const CustomiseCalendar = ({
  openCal,
  setOpenCal,
  refOne,
  range,
  setRange,
  calenderdirection,
  calenderHeight,
  calenderWidth,
}) => {
  useEffect(() => {
    document.addEventListener('keydown', hideInEscape, true);
    document.addEventListener('click', hideOnClickOutside, true);

    // Cleanup event listeners on unmount
    return () => {
      document.removeEventListener('keydown', hideInEscape, true);
      document.removeEventListener('click', hideOnClickOutside, true);
    };
  }, []);

  const hideInEscape = (e) => {
    if (e.key === 'Escape') {
      setOpenCal(false);
    }
  };

  const hideOnClickOutside = (e) => {
    if (refOne?.current && !refOne.current.contains(e.target)) {
      setOpenCal(false);
    }
  };

  return (
    <Box
      sx={{
        p: '.3rem',
        px: '.5rem',
        border: '1px solid black',
        borderRadius: '8px',
        backgroundColor: 'White',
        boxShadow: '4px 4px 4px 0px rgba(127, 130, 222, 0.3)',
      }}
    >
      <Typography
        sx={{
          fontSize: '0.9rem',
        }}
      >
        Dates
      </Typography>
      <Box
        sx={{
          display: 'flex',
        }}
      >
        <Typography
          onClick={() => setOpenCal((openCal) => !openCal)}
          variant="body1"
          sx={{
            textAlign: 'left',
            fontSize: { xs: '.6rem', sm: '.8rem', md: '1rem' },
            color: '#555555',
            '&:hover': { cursor: 'pointer' },
          }}
        >
          {range ? format(range[0].startDate, 'MM/dd/yyyy') : 'Enter Dates'}
        </Typography>
        <Typography
          sx={{
            mx: '1rem',
          }}
        >
          to
        </Typography>
        <Typography
          onClick={() => setOpenCal(!openCal)}
          variant="body1"
          sx={{
            textAlign: 'left',
            fontSize: { xs: '.6rem', sm: '.8rem', md: '1rem' },
            color: '#555555',
            '&:hover': { cursor: 'pointer' },
          }}
        >
          {range ? format(range[0].endDate, 'MM/dd/yyyy') : 'Enter Dates'}
        </Typography>
      </Box>
      <Box
        ref={refOne}
        sx={{
          position: 'absolute',
          ml: '-0.5rem',
          mt: '.8rem',
        }}
      >
        {openCal && (
          <Box
            sx={{
              position: 'relative',
              height: 'auto',
            }}
          >
            <DateRange
              onChange={(item) => setRange([item.selection])}
              editableDateInputs={true}
              moveRangeOnFirstSelection={false}
              ranges={range}
              months={2}
              direction={calenderdirection}
              className="calendarElement"
              style={{
                width: calenderWidth,
                height: calenderHeight,
              }}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default CustomiseCalendar;