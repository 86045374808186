import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import UserDetails from "../../component/UserDetails";
import Buttons from "../../component/UI/Buttons";
import Textfields from "../../component/UI/TextFields";
import UserAvatar from "../../Assets/Images/avatar.png";
import axios from "axios";

const Profile = () => {
  const [editDetails, setEditDetails] = useState(false);
  const [userCred, setUserCred] = useState({
    firstName: "",
    lastName: "",
    img: null,
    gender: "",
    phone: "",
    email: "",
    country: "",
  });

  const [updatedUserCred, setUpdatedUserCred] = useState({
    firstName: "",
    lastName: "",
    img: null,
    gender: "",
    phone: "",
    email: "",
    country: "",
  });

  const handleUserCred = (e) => {
    const { name, value } = e.target;
    setUserCred((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleEditDetails = () => {
    setEditDetails(!editDetails);
  };

  useEffect(() => {
    setUserCred((prev) => ({
      ...prev,
      firstName: `${localStorage.getItem("hostFirstName")}`,
      lastName: localStorage.getItem("hostLastName"),
      img: localStorage.getItem("hostAvatar"),
      gender: localStorage.getItem("hostGender"),
      phone: localStorage.getItem("hostMobile"),
      email: localStorage.getItem("hostEmail"),
      country: localStorage.getItem("hostCountry"),
    }));
  }, []);

  const handleUpdateUser = async () => {
    const transformedData = {
      avatar: userCred.img,
      first_name: userCred.firstName,
      last_name: userCred.lastName,
      gender: userCred.gender,
      mobile: userCred.phone,
    };

    try {
      const res = await axios.put(
        `${process.env.REACT_APP_API}account/user-update`,
        transformedData,
        {
          headers: {
            authorization: `Token ${localStorage.getItem("hostToken")}`,
          },
        }
      );
      console.log("----- ", res);
      localStorage.setItem("hostAvatar", res?.data?.avatar);
      localStorage.setItem("hostFirstName", res?.data?.first_name);
      localStorage.setItem("hostLastName", res?.data?.last_name);
      localStorage.setItem("hostGender", res?.data?.gender);
      localStorage.setItem("hostMobile", res?.data?.mobile);
    } catch (err) {
      console.log(err);
    }
    setEditDetails(!editDetails);
  };

  return (
    <Box
      sx={{
        backgroundColor: "White",
        width: "90%",
        pb: "4rem",
        borderRadius: "10px",
        boxShadow: "-4px 2px 4px 0px rgba(0, 0, 0, 0.08)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          pr: "1rem",
          pt: "1rem",
        }}
      >
        {editDetails ? (
          <Buttons
            text="Save"
            textColor="White"
            bgColor="#232675"
            textWeight="bold"
            btnIcon={<SaveOutlinedIcon />}
            btnClick={handleUpdateUser}
          />
        ) : (
          <Buttons
            text="Edit"
            textColor="White"
            bgColor="#232675"
            textWeight="bold"
            btnIcon={<ModeEditOutlineOutlinedIcon />}
            btnClick={handleEditDetails}
          />
        )}
      </Box>
      {editDetails ? (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                width: "10rem",
                height: "10rem",
                border: "1px solid #bdbdbd",
                borderRadius: "5px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  color: "#9a9899",
                  mt: ".5rem",
                  mr: ".5rem",
                }}
              >
                <ModeEditOutlineOutlinedIcon />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    height: "5rem",
                    width: "5rem",
                    borderRadius: "50%",
                  }}
                >
                  {userCred.img === "null" ? (
                    <img
                      src={UserAvatar}
                      style={{ height: "100%", width: "100%" }}
                      alt="Icon"
                      onError={(e) => {
                        e.onerror = null;
                        e.target.src = require("../../Assets/Images/no-preview.jpeg");
                      }}
                    />
                  ) : (
                    <img
                      src={userCred.img}
                      style={{ height: "100%", width: "100%" }}
                      alt="Icon"
                      onError={(e) => {
                        e.onerror = null;
                        e.target.src = require("../../Assets/Images/no-preview.jpeg");
                      }}
                    />
                  )}
                </Box>
                <Box
                  sx={{
                    mt: ".5rem",
                  }}
                >
                  <Typography>Profile Photo</Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mt: "4rem",
              px: "2rem",
            }}
          >
            <Box
              sx={{
                width: "30%",
              }}
            >
              <Textfields
                labelText="First Name"
                fieldName="firstName"
                textValue={userCred.firstName}
                fieldChange={handleUserCred}
              />
            </Box>
            <Box
              sx={{
                width: "30%",
              }}
            >
              <Textfields
                labelText="Last Name"
                fieldName="lastName"
                textValue={userCred.lastName}
                fieldChange={handleUserCred}
              />
            </Box>
            <Box
              sx={{
                width: "30%",
              }}
            >
              <Textfields
                labelText="Gender"
                fieldName="gender"
                textValue={userCred.gender}
                fieldChange={handleUserCred}
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mt: "2rem",
              px: "2rem",
            }}
          >
            <Box
              sx={{
                width: "30%",
              }}
            >
              <Textfields
                labelText="Phone number"
                fieldName="phone"
                fieldType="number"
                textValue={userCred.phone}
                fieldChange={handleUserCred}
              />
            </Box>
            <Box
              sx={{
                width: "30%",
              }}
            >
              <Textfields
                labelText="Email"
                fieldName="email"
                fieldType="email"
                textValue={userCred.email}
                fieldChange={handleUserCred}
              />
            </Box>
            <Box
              sx={{
                width: "30%",
              }}
            >
              <Textfields
                labelText="Country"
                fieldName="country"
                textValue={userCred.country}
                fieldChange={handleUserCred}
              />
            </Box>
          </Box>
        </>
      ) : (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                width: "10rem",
                height: "10rem",
                border: "1px solid #bdbdbd",
                borderRadius: "5px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  mt: "1rem",
                }}
              >
                <Box
                  sx={{
                    height: "6rem",
                    width: "6rem",
                    borderRadius: "50%",
                    backgroundColor: "gray",
                  }}
                >
                  {userCred.img === "null" ? (
                    <img
                      src={UserAvatar}
                      style={{ height: "100%", width: "100%" }}
                      alt="Icon"
                      onError={(e) => {
                        e.onerror = null;
                        e.target.src = require("../../Assets/Images/no-preview.jpeg");
                      }}
                    />
                  ) : (
                    <img
                      src={userCred.img}
                      style={{ height: "100%", width: "100%" }}
                      alt="Icon"
                      onError={(e) => {
                        e.onerror = null;
                        e.target.src = require("../../Assets/Images/no-preview.jpeg");
                      }}
                    />
                  )}
                </Box>
                <Box
                  sx={{
                    mt: "1rem",
                  }}
                >
                  <Typography>Profile Photo</Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mt: "4rem",
              px: "2rem",
            }}
          >
            <UserDetails
              field="Full Name"
              value={`${userCred.firstName} ${userCred.lastName}`}
            />
            <UserDetails field="Gender" value={userCred.gender} />
            <UserDetails field="Phone Number" value={userCred.phone} />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mt: "2rem",
              px: "2rem",
              width: "48%",
            }}
          >
            <UserDetails field="Email" value={userCred.email} />
            <UserDetails field="Country" value={userCred.country} />
          </Box>
        </>
      )}
    </Box>
  );
};

export default Profile;
