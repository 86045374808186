import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

import LoginImage from "../../Assets/Images/Login.png";
import Textfields from "../../component/UI/TextFields";
import Buttons from "../../component/UI/Buttons";
import { Password } from "@mui/icons-material";
import { toast, Toaster } from "react-hot-toast";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Logo from "../../Assets/Company/logo.png";

const Login = () => {
  const navigate = useNavigate();
  const xs = useMediaQuery("(max-Width: 600px)");
  const sm = useMediaQuery("(min-width: 601px) and (max-width: 900px)");
  const md = useMediaQuery("(min-width: 901px) and (max-width: 1500px)");

  const [userDetails, setUserDetails] = useState({
    email: "",
    password: "",
  });

  const handleForm = (e) => {
    const { name, value } = e.target;
    setUserDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API}account/login`,
        userDetails
      );
      if (res?.data?.user?.role !== "host") {
        toast.error("Please Login as Host", { duration: 2000 });
      } else {
        localStorage.setItem("hostToken", res?.data?.user?.Token);
        localStorage.setItem("hostRole", res?.data?.user?.role);
        localStorage.setItem("hostAvatar", res?.data?.user?.avatar);
        localStorage.setItem("hostFirstName", res?.data?.user?.first_name);
        localStorage.setItem("hostLastName", res?.data?.user?.last_name);
        localStorage.setItem("hostGender", res?.data?.user?.gender);
        localStorage.setItem("hostMobile", res?.data?.user?.mobile);
        localStorage.setItem("hostEmail", res?.data?.user?.email);
        localStorage.setItem("hostCountry", "Italy");
        toast.success("Successfully Login");
        !md ? navigate("/mobile") : navigate("/");
      }
    } catch (err) {
      err?.response?.data?.error ? toast.error(err?.response?.data?.error) : toast.error(err?.response?.data?.message)
      console.log(err);
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "97.5vh",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Toaster
        position="top-right"
        toastOptions={{
          success: {
            duration: 4000,
            style: {
              background: "green",
              color: "white",
            },
            iconTheme: {
              primary: "white",
              secondary: "green",
            },
          },
          error: {
            duration: 2000,
            style: {
              background: "red",
              color: "white",
            },
            iconTheme: {
              primary: "white",
              secondary: "red",
            },
          },
        }}
      />
      {md && (
        <Box
          sx={{
            width: "40%",
            height: "85%",
            margin: "3rem",
          }}
        >
          <img
            src={LoginImage}
            style={{ height: "100%", width: "100%" }}
            alt="Icon"
            onError={(e) => {
              e.onerror = null;
              e.target.src = require("../../Assets/Images/no-preview.jpeg");
            }}
          />
        </Box>
      )}
      <Box
        sx={{
          width: { xs: "100%", sm: "100%", md: "50%" },
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: { xs: "center", sm: "center", md: "unset" },
          flexDirection: "column",
        }}
      >
        {!md && (
          <Box
            sx={{
              height: "6rem",
              width: "6rem",
              borderRadius: "50%",
            }}
          >
            <img
              src={Logo}
              style={{ height: "100%", width: "100%" }}
              alt="Icon"
              onError={(e) => {
                e.onerror = null;
                e.target.src = require("../../Assets/Images/no-preview.jpeg");
              }}
            />
          </Box>
        )}
        <Typography
          sx={{
            fontSize: "2.5rem",
            fontWeight: "bold",
          }}
        >
          Welcome Back!
        </Typography>

        <Typography
          sx={{
            fontSize: ".9rem",
            mt: ".5rem",
          }}
        >
          Please login to continue
        </Typography>
        <form onSubmit={handleLogin}>
          <Box
            sx={{
              mt: "1.5rem",
              width: { xs: "100%", sm: "100%", md: "70%" },
            }}
          >
            <Textfields
              labelText="E-mail Address"
              fieldType="email"
              textValue={userDetails.email}
              fieldName="email"
              fieldChange={handleForm}
            />
          </Box>
          <Box
            sx={{
              mt: "2.5rem",
              width: { xs: "100%", sm: "100%", md: "70%" },
            }}
          >
            <Textfields
              labelText="Password"
              fieldType="password"
              textValue={userDetails.Password}
              fieldName="password"
              fieldChange={handleForm}
            />
          </Box>
          <Box
            sx={{
              mt: "2.5rem",
              width: {xs:"100%", sm:"100%", md:"70%"},
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Buttons
              text="Log In"
              bgColor="#232675"
              textColor="White"
              btnWidth={xs ? "70%" : sm ? "70%" : "100%"}
              btnVerticalPadding=".8rem"
              btnType="submit"
            />
          </Box>
        </form>
      </Box>
    </Box>
  );
};

export default Login;
