import React from 'react'
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const Filters = ({filterName, data, filterChange, statusValue}) => {
  //   const [value, setValue] = React.useState("");

  // const handleChange = (event) => {
  //   setValue(event.target.value);
  // };

  return (
    <Box
        sx={{
          p: ".3rem",
          px:".5rem",
          border: "1px solid black",
          borderRadius: "8px",
          backgroundColor:"White",
          boxShadow: '4px 4px 4px 0px rgba(127, 130, 222, 0.3)'
        }}
      >
        <Typography
        sx={{
          fontSize:"0.9rem"
        }}
        >{filterName}</Typography>
        <Box sx={{}}>
          <FormControl fullWidth>
            <Select
              value={statusValue}
              onChange={filterChange}
              displayEmpty
              inputProps={{ "aria-label": "Select an Option" }}
              MenuProps={{
                PaperProps: {
                  sx: {
                    mt: ".8rem",
                  },
                },
              }}
              sx={{
                height: 30,
                fontSize: '0.9rem',
                '& .MuiSelect-select': {
                  padding: '8px 0',
                },
                '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
          },
              }}
            >
              <MenuItem value="" disabled>
                Select an Option
              </MenuItem>
              {data.map((item,index) => (
                <MenuItem key={item} value={item}
                sx={{
                  fontSize:"0.8rem"
                }}
                >{item}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Box>
  )
}

export default Filters