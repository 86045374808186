import React, { useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import EsimSearch from '../../Assets/Images/esimSearch.png';

const SearchBar = ({ toSearch, }) => {
  const [searchText, setSearchText] = useState("");

  const handleTextChange = (event) => {
    const value = event.target.value;
    // Only update the state if the value is numeric
    if (/^\d*$/.test(value)) {
      setSearchText(value);
    }
  };

  const handleSearch = () => {
    if (searchText) {
      toSearch(searchText);
    }
  };

  return (
    <>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          mt: "1.5rem",
        }}
      >
        <Box
          sx={{
            width: { xs: "90%", sm: "70%", md: "60%" },
            height: "2rem",
            border: "1px solid gray",
            borderRadius: "5px",
            display: "flex",
            justifyContent: "space-between",
            padding: ".7rem",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              width: { xs: "75%", sm: "85%", md: "90%" },
            }}
          >
            <TextField
              placeholder="Search by Booking ID"
              fullWidth
              value={searchText}
              onChange={handleTextChange}
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
              sx={{
                "& .MuiInputBase-root": {
                  height: "35px",
                },
                "& .MuiOutlinedInput-root": {
                  border: "none",
                },
                "& fieldset": {
                  border: "none",
                },
              }}
            />
          </Box>

          <Box
            sx={{
              width: { xs: "13%", sm: "8%", md: "7%" },
              display: "flex",
              alignItems: "center",
            }}
          >
            <img
              src={EsimSearch}
              style={{ height: "2.5rem", width: "2.5rem", cursor: "pointer" }}
              onClick={handleSearch}
              alt="Search"
              onError={(e) => {
                e.onerror = null;
                e.target.src = require("../../Assets/Images/no-preview.jpeg");
              }}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default SearchBar;