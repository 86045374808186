import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import axios from "axios";

// import { PropertyData } from "../constant/PropertyData";

const DashboardPage = ({ PropertyData }) => {

  // useEffect(() => {
  //   handleGetProperty();
  // }, []);

  // const handleGetProperty = async () => {
  //   try {
  //     const res = await axios.get(
  //       "https://testing.voyagerstales.com/api/get-user-property/",
  //       {
  //         headers: {
  //           Authorization: `Token ${localStorage.getItem("hostToken")}`,
  //         },
  //       }
  //     );
  //     // console.log("properties-", res?.data?.get_property);
  //     setPropertyData(res?.data?.get_property);
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };


  const handlePreview = async (id, name) => {
    const updatedString = name.replace(/ /g,"-");
    window.open(`https://voyagerstales.com/pdp/${updatedString}/${id}`, '_blank');
  }

  return (
    <>
      <Box
        sx={{
          width: "80%",
          mt: "4rem",
        }}
      >
        {PropertyData ? (PropertyData?.map((item, index) => (
          <Box
            key={index}
            sx={{
              p: "1rem",
              border: "0.5px solid #9a9898",
              borderRadius: "10px",
              backgroundColor: "#fffdf9",
              mt: "2rem",
              boxShadow: "4px 4px 4px 0px #E5F2FD",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "bold",
                  }}
                >
                  Property Name:
                </Typography>
                <Typography>&nbsp;{item.property_name}</Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "bold",
                  }}
                >
                  Type:
                </Typography>
                <Typography>&nbsp;{item.property_type?.name}</Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "bold",
                  }}
                >
                  ID:
                </Typography>
                <Typography>&nbsp;{item.id}</Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mt: "2rem",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "bold",
                  }}
                >
                  Status:
                </Typography>
                <Typography
                  sx={{
                    color: item.is_active === true ? "#7dc10c" : "red",
                  }}
                >
                  &nbsp;{item.is_active == true ? "Active" : "Inactive"}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                }}
              >
                {/* <Typography
                  sx={{
                    fontWeight: "bold",
                  }}
                >
                  Duration:
                </Typography> */}
                <Typography
                  sx={{
                    color: "#393ebc",
                  }}
                >
                  &nbsp;{item.month}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                }}
              >
                {/* <Button
                  sx={{
                    borderRadius: "8px 0 0 8px",
                    backgroundColor: "#232675",
                    color: "White",
                    textTransform: "none",
                    fontWeight: "bold",
                    border: "1px solid black",
                    borderRight: "none",
                    "&:hover": {
                      backgroundColor: "#232675",
                      color: "White",
                    },
                  }}
                >
                  Edit
                </Button> */}
                <Button
                  sx={{
                    borderRadius: "8px",
                    backgroundColor: "White",
                    color: "#232675",
                    textTransform: "none",
                    fontWeight: "bold",
                    border: "1px solid black",
                    "&:hover": {
                      backgroundColor: "White",
                      color: "#232675",
                    },
                  }}
                  onClick={() => handlePreview(item.id, item.property_name)}
                >
                  Preview
                </Button>
              </Box>
            </Box>
          </Box>
        ))) : (
          <Typography>
            No Property Found..
          </Typography>
        )}
      </Box>
    </>
  );
};

export default DashboardPage;
