import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import axios from "axios";

import SearchBar from "../SearchBar";
import BookingFilters from "../BookingFilters";
import Tables from "../UI/table";
import addDays from "date-fns/addDays";
import Buttons from "../UI/Buttons";

const tableHead = [
  "Booked on",
  "Guest Name",
  "Booking Id",
  "Number of guests",
  "Checkin",
  "Checkout",
  "Booking Status",
];

const Bookings = ({ propertyNames }) => {
  const [value, setValue] = useState(propertyNames[0]?.id || "");
  const [bookingData, setBookingData] = useState([]);
  const [buttonAppearence, setButtonAppearence] = useState(1);
  const [valueStatus, setValueStatus] = useState("");
  const [isSearch, setIsSearch] = useState(false);

  const [range, setRange] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 2),
      key: "selection",
    },
  ]);

  useEffect(() => {
    if (!propertyNames.some((item) => item.id === value)) {
      setValue(propertyNames[0]?.id || "");
    }
    handleGetProperty();
  }, [propertyNames, value]);

  const handleGetProperty = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API}api/all-booked-cancel-booking/?page=1&destination=${value}`,
        {
          headers: {
            Authorization: `Token ${localStorage.getItem("hostToken")}`,
          },
        }
      );
      setBookingData(res?.data?.all_booking);
      setValueStatus("");
      setButtonAppearence(1);
    } catch (err) {
      console.log(err);
    }
  };

  const handleChange = (event) => {
    console.log(event.target.value);
    setValue(event.target.value);
  };

  const handleUpcomingProperty = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API}api/get-upcomeing-booking/?page=1&destination=${value}`,
        {
          headers: {
            Authorization: `Token ${localStorage.getItem("hostToken")}`,
          },
        }
      );
      setBookingData(res?.data);
      setValueStatus("");
      setButtonAppearence(2);
    } catch (err) {
      console.log(err);
    }
  };

  const handlePastProperty = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API}api/get-past-booking/?page=1&destination=${value}`,
        {
          headers: {
            Authorization: `Token ${localStorage.getItem("hostToken")}`,
          },
        }
      );
      setBookingData(res?.data);
      setValueStatus("");
      setButtonAppearence(3);
    } catch (err) {
      console.log(err);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const handleFilters = async () => {
    const dateStart = formatDate(range[0].startDate);
    const dateEnd = formatDate(range[0].endDate);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API}api/get-booking-by-filter/?start_date=${dateStart}&end_date=${dateEnd}&destination=${value}`,
        {
          headers: {
            Authorization: `Token ${localStorage.getItem("hostToken")}`,
          },
        }
      );
      setBookingData(res?.data);
      setButtonAppearence(0);
      setValueStatus("");
    } catch (err) {
      console.log(err);
    }
  };

  const handleStausChange = async (event) => {
    setValueStatus(event.target.value);
    var checkedStatus;
    if (event.target.value === "Booked") {
      checkedStatus = "get-booking-approved/";
    } else if (event.target.value === "Cancelled") {
      checkedStatus = "get-booking-cancel/";
    }

    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API}api/${checkedStatus}?page=1&destination=${value}`,
        {
          headers: {
            Authorization: `Token ${localStorage.getItem("hostToken")}`,
          },
        }
      );
      setBookingData(res?.data);
      setButtonAppearence(0);
    } catch (err) {
      console.log(err);
    }
  };

  const handleSearch = async (typedText) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API}api/get-booking-by-id/?booking_id=${typedText}`,
        {
          headers: {
            Authorization: `Token ${localStorage.getItem("hostToken")}`,
          },
        }
      );
      setBookingData(res?.data?.data);
      setButtonAppearence(0);
      setIsSearch(true);
    } catch (err) {
      console.error("Error searching property:", err);
    }
  };

  const handleClearSearch = () => {
    handleGetProperty();
    setIsSearch(false);
  };

  return (
    <>
      <Box
        sx={{
          width: "20%",
        }}
      >
        {propertyNames && propertyNames.length > 0 && (
          <Box
            sx={{
              p: ".3rem",
              px: ".5rem",
              border: "1px solid black",
              borderRadius: "8px",
              backgroundColor: "White",
              boxShadow: "4px 4px 4px 0px rgba(127, 130, 222, 0.3)",
              mt: "2rem",
            }}
          >
            <Typography
              sx={{
                fontSize: "0.9rem",
              }}
            >
              Select Property
            </Typography>
            <Box sx={{}}>
              <FormControl fullWidth>
                <Select
                  value={value}
                  onChange={handleChange}
                  displayEmpty
                  inputProps={{ "aria-label": "Select an Option" }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        mt: ".8rem",
                      },
                    },
                  }}
                  sx={{
                    height: 30,
                    fontSize: "0.9rem",
                    "& .MuiSelect-select": {
                      padding: "8px 0",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                  }}
                >
                  {propertyNames.map((item, index) => (
                    <MenuItem
                      key={item?.property_name}
                      value={item?.id}
                      sx={{
                        fontSize: "0.8rem",
                      }}
                    >
                      {item?.property_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Box>
        )}
      </Box>
      <Box
        sx={{
          width: "100%",
        }}
      >
        <SearchBar toSearch={handleSearch} />
      </Box>
      {/* <Box
        sx={{
          width: "90%",
          mt: "1.5rem",
        }}
      >
        <BookingFilters allBtn={handleGetProperty} upcomingBtn={handleUpcomingProperty} pastBtn={handlePastProperty} btnCode={buttonAppearence} updateRange={range} setUpdateRange={setRange} applyBtn={handleFilters} statusChange={handleStausChange} value={valueStatus} />
      </Box> */}

      {!isSearch ? (
        <Box
          sx={{
            width: "90%",
            mt: "1.5rem",
          }}
        >
          <BookingFilters
            allBtn={handleGetProperty}
            upcomingBtn={handleUpcomingProperty}
            pastBtn={handlePastProperty}
            btnCode={buttonAppearence}
            updateRange={range}
            setUpdateRange={setRange}
            applyBtn={handleFilters}
            statusChange={handleStausChange}
            value={valueStatus}
          />
        </Box>
      ) : (
        <Box
          sx={{
            width: "81%",
            mt: "1.5rem",
            display: "flex",
            justifyContent: "end",
          }}
        >
          <Buttons
            text="Clear"
            btnClick={handleClearSearch}
            bgColor="#232675"
            textColor="White"
            btnWidth="12%"
          />
        </Box>
      )}

      <Box
        sx={{
          width: "90%",
          mt: "3rem",
        }}
      >
        <Tables headData={tableHead} bodyData={bookingData} />
      </Box>
    </>
  );
};

export default Bookings;
